// A collections of functions to manipulate immutable objects
// A check is performed to make sure the immutable object supports the method
// They follow the form of:
// (...args) => (collection) => collection[method](...args)
// All exports from this module are tested to follow the above form
import { hasMethod } from './functional';

/*eslint-disable no-undefined*/
export const get = (key, notSetValue) => (collection) =>
	hasMethod('get', collection) ? collection.get(key, notSetValue) : notSetValue;

export const getIn = (keyPath, notSetValue) => (collection) =>
	hasMethod('getIn', collection)
		? collection.getIn(keyPath, notSetValue)
		: notSetValue;

export const has = (key) => (collection) =>
	hasMethod('has', collection) ? collection.has(key) : false;

export const set = (key, value) => (collection) =>
	hasMethod('set', collection) ? collection.set(key, value) : undefined;

export const setIn = (keyPath, value) => (collection) =>
	hasMethod('setIn', collection) ? collection.setIn(keyPath, value) : undefined;

export const update =
	(...args) =>
	(collection) =>
		hasMethod('update', collection) ? collection.update(...args) : undefined;

export const updateIn =
	(...args) =>
	(collection) =>
		hasMethod('updateIn', collection)
			? collection.updateIn(...args)
			: undefined;

export const remove = (key) => (collection) =>
	hasMethod('remove', collection) ? collection.remove(key) : undefined;

export const removeIn = (keyPath) => (collection) =>
	hasMethod('removeIn', collection) ? collection.removeIn(keyPath) : undefined;

export const merge =
	(...iterable) =>
	(collection) =>
		hasMethod('merge', collection) ? collection.merge(...iterable) : undefined;

export const mergeIn =
	(...iterable) =>
	(collection) =>
		hasMethod('mergeIn', collection)
			? collection.mergeIn(...iterable)
			: undefined;

export const mergeDeep =
	(...iterable) =>
	(collection) =>
		hasMethod('mergeDeep', collection)
			? collection.mergeDeep(...iterable)
			: undefined;

export const map = (mapper) => (collection) =>
	hasMethod('map', collection) ? collection.map(mapper) : undefined;

export const flatMap = (mapper) => (collection) =>
	hasMethod('flatMap', collection) ? collection.flatMap(mapper) : undefined;

export const filter = (predicate) => (collection) =>
	hasMethod('filter', collection) ? collection.filter(predicate) : undefined;

export const mapEntries = (mapper) => (collection) =>
	hasMethod('mapEntries', collection)
		? collection.mapEntries(mapper)
		: undefined;

export const reduce = (iterator, startValue) => (collection) =>
	hasMethod('reduce', collection)
		? collection.reduce(iterator, startValue)
		: undefined;

export const union =
	(...collections) =>
	(set) =>
		hasMethod('union', set) ? set.union(...collections) : undefined;

export const concat = (values) => (collection) =>
	hasMethod('concat', collection) ? collection.concat(values) : undefined;

// every on an empty collection returns true
// so it should do the same on a nonexisting collection
export const every = (predicate) => (collection) =>
	hasMethod('every', collection) ? collection.every(predicate) : true;

// some on an empty collection returns false
// so it should do the same on a nonexisting collection
export const some = (predicate) => (collection) =>
	hasMethod('some', collection) ? collection.some(predicate) : false;

export const findLastKey = (predicate) => (collection) =>
	hasMethod('findLastKey', collection)
		? collection.findLastKey(predicate)
		: undefined;

export const findKey = (predicate) => (collection) =>
	hasMethod('findKey', collection) ? collection.findKey(predicate) : undefined;

export const find = (predicate, context, notSetValue) => (collection) =>
	hasMethod('find', collection)
		? collection.find(predicate, context, notSetValue)
		: undefined;

export const findIndex = (predicate, context) => (collection) =>
	hasMethod('findIndex', collection)
		? collection.findIndex(predicate, context)
		: undefined;

export const groupBy = (grouper) => (collection) =>
	hasMethod('groupBy', collection) ? collection.groupBy(grouper) : undefined;

export const sort = (comparator) => (collection) =>
	hasMethod('sort', collection) ? collection.sort(comparator) : undefined;

export const sortBy = (comparatorValueMapper, comparator) => (collection) =>
	hasMethod('sortBy', collection)
		? collection.sortBy(comparatorValueMapper, comparator)
		: undefined;

export const join = (separator) => (collection) =>
	hasMethod('join', collection) ? collection.join(separator) : '';

export const withMutations = (updater) => (collection) =>
	hasMethod('withMutations', collection)
		? collection.withMutations(updater)
		: undefined;

const notFoundValue = -1;
export const indexOf = (value) => (collection) =>
	hasMethod('indexOf', collection) ? collection.indexOf(value) : notFoundValue;

export const setSize = (size) => (collection) =>
	hasMethod('setSize', collection) ? collection.setSize(size) : undefined;

export const push = (value) => (collection) =>
	hasMethod('push', collection) ? collection.push(value) : undefined;

export const unshift = (value) => (collection) =>
	hasMethod('unshift', collection) ? collection.unshift(value) : undefined;
