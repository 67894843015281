'use client';

import {
	WebApiPageData,
	isWebApiPageData,
} from '@/globals/dataCollector/types/dataCollector.types';
import { StructuredData } from './StructuredData';
import { useEffect } from 'react';

export const DefaultScripts = ({ pageData }: { pageData: WebApiPageData }) => {
	useEffect(() => {
		// As soon as client is rendered, AB-tasty can be used (otherwise NextJS hydration errors will pop up)
		// See: https://developers.abtasty.com/docs/tag/tag-window#locking-tag-execution
		if ('unlockABTasty' in window) {
			window.unlockABTasty();
		}
	}, []);

	return (
		<>{isWebApiPageData(pageData) && <StructuredData pageData={pageData} />}</>
	);
};
