import { config } from 'moduleAlias/config';

import { translations, Translations } from './translations';
import { interpolateString } from '../helpers/interpolateString';

interface TranslateOptions {
	transform?: 'lowercase' | 'uppercase' | 'capitalize';
	language?: 'en' | 'nl'; // override the config-language
	data?: Record<string, string>; // data for interpolation of strings like `hello ${thing}`, {data: { thing: 'world' }}
}

/**
 * Translate returns a translated string, based on a key.
 * Use transform to change casing and data to interpolate placeholders in translations
 */
export const translate = (
	key: keyof Translations,
	options: TranslateOptions = {}
): string => {
	const langCode = options?.language || config.languageCode;
	const locale = config.localization?.locale;

	if (!langCode == null || !locale == null) {
		// if config file is incomplete/corrupted
		throw new Error(
			`Could not find languageCode or locate information from config files`
		);
	}
	if (!translations[key]?.[langCode] == null) {
		throw new Error(`Could not find ${langCode}-translation for key: ${key}`);
	}

	const { transform, data } = options;

	// change placeholders in string like "Hello ${name}" with correct content
	const translation =
		data != null
			? interpolateString(translations[key][langCode], data)
			: translations[key][langCode];

	switch (transform) {
		case 'lowercase':
			return translation.toLocaleLowerCase(locale);
		case 'uppercase':
			return translation.toLocaleUpperCase(locale);
		case 'capitalize':
			return (
				translation.charAt(0).toLocaleUpperCase(locale) + translation.slice(1)
			);
		default:
			return translation;
	}
};
