export const cooperatieTranslations = {
	'rechtsvorm-cooperatie': {
		en: 'Cooperative',
		nl: 'Coöperatie',
	},
	'rechtsvorm-cooperatie-summary-point-1': {
		en: "setup using an actuary's services",
		nl: 'oprichten met tussenkomst van notaris',
	},
	'rechtsvorm-cooperatie-summary-point-2': {
		en: 'at least two partners',
		nl: 'minimaal twee partners',
	},
	'rechtsvorm-cooperatie-summary-point-3': {
		en: 'for entrepreneurs collaborating in a project',
		nl: 'voor ondernemers die gezamenlijk werken aan een project',
	},
	'rechtsvorm-cooperatie-summary-point-4': {
		en: 'easy access and exit of members',
		nl: 'makkelijk in- en uitreden leden',
	},
	'rechtsvorm-cooperatie-summary-point-5': {
		en: 'not personally liable for any debts',
		nl: 'niet persoonlijk aansprakelijk voor eventuele schulden',
	},
	'rechtsvorm-cooperatie-summary-point-6': {
		en: 'possible tax relief schemes: private business ownership allowance, SME profit exemption, tax relief for new companies',
		nl: 'recht op zelfstandigenaftrek en mkb-winstvrijstelling, starters profiteren van startersaftrek',
	},
} as const;
