export const bvTranslations = {
	'rechtsvorm-bv': {
		en: 'Private Limited Company (bv)',
		nl: 'Besloten vennootschap (bv)',
	},
	'rechtsvorm-bv-summary-point-1': {
		en: "setup using an actuary's services",
		nl: 'oprichten met tussenkomst van notaris',
	},
	'rechtsvorm-bv-summary-point-2': {
		en: 'cost of setup € 500-1000 actuary; € 600-1,800 annual administrative obligations',
		nl: 'kosten eenmalig € 500-1000 notaris, jaarlijks € 600-1800 administratie',
	},
	'rechtsvorm-bv-summary-point-3': {
		en: 'you can find the current [registration fee](https://www.kvk.nl/english/registration/registration-fee/) on KVK.nl/english',
		nl: 'huidige [inschrijfkosten](https://www.kvk.nl/inschrijven-en-wijzigen/inschrijven-bij-de-kamer-van-koophandel/inschrijfvergoeding/) vindt u op KVK.nl',
	},
	'rechtsvorm-bv-summary-point-4': {
		en: 'not personally liable for any company debts',
		nl: 'niet persoonlijk aansprakelijk voor eventuele schulden',
	},
	'rechtsvorm-bv-summary-point-5': {
		en: 'capital divided into shares',
		nl: 'kapitaal verdeeld in aandelen',
	},
	'rechtsvorm-bv-summary-point-6': {
		en: 'tax burden lower when profits increase',
		nl: 'lagere belastingdruk bij hogere winsten',
	},
} as const;
