export const commonTranslations = {
	'read more about': {
		en: 'Read more about ${rechtsvormLabel}',
		nl: 'Alles over ${rechtsvormLabel}',
	},
	'based on your answers': {
		en: 'Based on your answers',
		nl: 'Gebaseerd op uw antwoorden',
	},
	'suitable legal forms': {
		en: 'Suitable legal forms',
		nl: 'Passende rechtsvormen',
	},
	'start over': {
		en: 'Start over',
		nl: 'Begin opnieuw',
	},
	'why does this matter': {
		en: 'Why does this matter?',
		nl: 'Waarom is dit belangrijk?',
	},
	'change answer to question': {
		en: 'Change answer to question: ${questionLabel}',
		nl: 'Antwoord wijzigen van vraag: ${questionLabel}',
	},
	continue: {
		en: 'Continue',
		nl: 'Verder',
	},
	'continue to question': {
		en: 'Continue to question: ${questionLabel}',
		nl: 'Verder naar vraag: ${questionLabel}',
	},
	'register now': {
		en: 'Register now',
		nl: 'Direct inschrijven',
	},
	'register rechtsvorm now': {
		en: 'Register ${rechtsvormLabel} now',
		nl: 'Direct ${rechtsvormLabel} inschrijven',
	},
	skip: {
		en: 'Skip',
		nl: 'Overslaan',
	},
	'skip question': {
		en: 'Skip question: ${questionLabel}',
		nl: 'Vraag overslaan: ${questionLabel}',
	},
	'question skipped': {
		en: 'Question skipped',
		nl: 'Vraag overgeslagen',
	},
	'take action title': {
		en: 'Take action',
		nl: 'Direct doen',
	},
	'take action text': {
		en: 'Click one of the legal structures if you want to know more or want to make an appointment to register with KVK',
		nl: 'Klik op een van de rechtsvormen als u er meer over wilt weten of een afspraak wilt maken om u in te schrijven bij KVK.',
	},
} as const;
