import Script from 'next/script';
import { posix } from 'path';

import environmentConfig from 'moduleAlias/environmentConfig';
import { config } from 'moduleAlias/config';
import { sanitizeUrl } from '@dop/shared/helpers/link';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { translate } from '@dop/shared/translate/translate';
import { WebApiPageData } from '@/globals/dataCollector/types/dataCollector.types';
import { Breadcrumb } from '@/globals/webApiTypes/common.types';

export const uiStructuredDataBreadcrumbs = 'sd-breadcrumbs'; // not really used for e2e-tests yet
export const uiStructuredDataSearchAction = 'sd-search-action'; // not really used for e2e-tests yet
export const uiStructuredDataOrganization = 'sd-organization'; // not really used for e2e-tests yet
export const uiStructuredDataArticle = 'sd-article'; // not really used for e2e-tests yet
export const uiStructuredDataSpeakable = '_sd-speakable'; // not really used for e2e-tests, but for easier location when manual testing

export const uiStructuredDataFaq = 'sd-faq';

const getBreadcrumbsName = ({ text }: Breadcrumb) => {
	if (typeof text === 'string') {
		return text;
	} else if (text.prefix != null && text.name != null) {
		return `${text.prefix} ${text.name}`;
	} else if (text.name != null) {
		return text.name;
	}

	throw new Error('Wrong content detected, expected string, got object');
};

const breadcrumbsData = (data: Array<Breadcrumb>) => ({
	'@context': 'http://schema.org',
	'@type': 'BreadcrumbList',
	itemListElement: data.map((item, index) => ({
		'@type': 'ListItem',
		position: index + 1,
		item: {
			'@id': new URL(item.href, environmentConfig.originUrl).href,
			name: getBreadcrumbsName(item),
		},
	})),
});

const searchAction = () => ({
	'@context': 'http://schema.org',
	'@type': 'WebSite',
	url: sanitizeUrl(environmentConfig.originUrl),
	potentialAction: [
		{
			'@type': 'SearchAction',
			target: decodeURI(
				new URL(
					posix.join(
						config.searchPath,
						`filter/ondernemersplein/zoekterm/{search_term_string}/pagina/1/`
					),
					environmentConfig.originUrl
				).href
			),
			'query-input': 'required name=search_term_string',
		},
	],
});

const organisation = () => ({
	'@context': 'http://schema.org',
	'@type': 'GovernmentOrganization',
	url: sanitizeUrl(environmentConfig.originUrl),
	logo:
		config.project === 'op'
			? new URL(`images/ondernemersplein-logo.png`, environmentConfig.originUrl)
					.href
			: new URL(`images/bgov-logo-512.png`, environmentConfig.originUrl),
	name: translate('siteTitle'),
	description: translate('siteDescription'),
	sameAs:
		config.project === 'op'
			? [
					'https://twitter.com/OP_Nederland',
					'https://www.linkedin.com/company/ondernemersplein/',
					'https://www.youtube.com/user/ondernemerspleinnl',
					'https://www.instagram.com/ondernemersplein.kvk.nl/',
			  ]
			: [
					'https://twitter.com/BusinessGovNL',
					'https://www.linkedin.com/company/businessgovnl/',
					'https://youtube.com/BusinessGovNL',
					'https://www.instagram.com/businessgovnl/',
			  ],
});

type Article = {
	url: string;
	headline: string;
	description?: string;
	image?: string;
	identifier?: string;
	dateModified?: string;
	datePublished?: string;
	wordCount?: number;
	author: WebApiPageData['author'];
	publisher: WebApiPageData['publisher'];
};

const articleData = ({
	url,
	headline,
	description,
	image,
	author,
	publisher,
	dateModified,
	datePublished,
	wordCount,
}: Article) => ({
	'@context': 'https://schema.org',
	'@type': 'Article',
	mainEntityOfPage: {
		'@type': 'WebPage',
		'@id': url,
	},
	headline,
	description,
	image,
	datePublished,
	dateModified,
	author: author
		? {
				'@type': 'Organization',
				name: author.name,
				url: sanitizeUrl(author.url),
				logo: {
					'@type': 'ImageObject',
					url: author.logo,
				},
				slogan: author.payoff,
		  }
		: undefined,
	publisher: publisher
		? {
				'@type': 'Organization',
				name: publisher.name,
				url: sanitizeUrl(publisher.url),
				logo: {
					'@type': 'ImageObject',
					url: publisher.logo,
				},
				slogan: publisher.payoff,
		  }
		: undefined,
	wordCount,
});

const speakable = ({ headline, url }: { headline: string; url: string }) => ({
	'@context': 'https://schema.org',
	'@type': 'WebPage',
	name: headline,
	url,
	speakable: {
		'@type': 'SpeakableSpecification',
		cssSelector: ['h1', '.intro'],
	},
});

const faqData = (data: Array<{ question: string; answer: string }>) => ({
	'@context': 'https://schema.org',
	'@type': 'FAQPage',
	mainEntity: data.map((question, index) => ({
		'@type': 'Question',
		position: index + 1,
		name: question.question,
		acceptedAnswer: {
			'@type': 'Answer',
			text: question.answer,
		},
	})),
});

const getStructuredData = (
	pageData: WebApiPageData
): {
	providerType: keyof typeof config.providerTypes;
	breadcrumbs: Array<Breadcrumb>;
	faqs: Array<{ question: string; answer: string }>;
	article: Article;
} => {
	const providerType = pageData.providerType;
	const siteTitle =
		pageData.title || pageData.windowTitle || translate('siteTitle');

	const breadcrumbs = (pageData.breadcrumbs?.linkList || []).concat({
		href: pageData.url,
		text: siteTitle,
		isExternal: false,
	});

	const faqs = pageData.faqMarkup || [];

	const article = {
		url: sanitizeUrl(pageData.url),
		headline: siteTitle,
		description: pageData.metadata?.['dcterms:description'],
		image: pageData.opengraph?.facebook?.['og:image'],
		datePublished: pageData.metadata?.['dcterms:created'],
		dateModified: pageData.metadata?.['dcterms:modified'],
		author: pageData.author,
		identifier: pageData.metadata?.['dcterms:identifier'],
		publisher: pageData.publisher,
		wordCount: pageData.wordCount,
	};

	return {
		providerType,
		breadcrumbs,
		faqs,
		article,
	};
};

export const StructuredData = ({ pageData }: { pageData: WebApiPageData }) => {
	const { providerType, breadcrumbs, faqs, article } =
		getStructuredData(pageData);

	return (
		<>
			<Script
				type="application/ld+json"
				id={uiStructuredDataOrganization}
				{...addTestSelector(uiStructuredDataOrganization)}
				dangerouslySetInnerHTML={{ __html: JSON.stringify(organisation()) }}
			/>
			<Script
				type="application/ld+json"
				id={uiStructuredDataSearchAction}
				{...addTestSelector(uiStructuredDataSearchAction)}
				dangerouslySetInnerHTML={{ __html: JSON.stringify(searchAction()) }}
			/>
			<Script
				type="application/ld+json"
				id={uiStructuredDataBreadcrumbs}
				{...addTestSelector(uiStructuredDataBreadcrumbs)}
				dangerouslySetInnerHTML={{
					__html: JSON.stringify(breadcrumbsData(breadcrumbs)),
				}}
			/>
			{providerType === 'article' && faqs.length > 0 && (
				<Script
					type="application/ld+json"
					id={uiStructuredDataFaq}
					{...addTestSelector(uiStructuredDataFaq)}
					dangerouslySetInnerHTML={{ __html: JSON.stringify(faqData(faqs)) }}
				/>
			)}
			{providerType === 'article' && (
				<Script
					type="application/ld+json"
					id={uiStructuredDataArticle}
					{...addTestSelector(uiStructuredDataArticle)}
					dangerouslySetInnerHTML={{
						__html: JSON.stringify(articleData(article)),
					}}
				/>
			)}
			{/* Added for a test with speakable content, currently no pages have speakable content */}
			{environmentConfig.speakablePages &&
				article.identifier != null &&
				environmentConfig.speakablePages.includes(article.identifier) && (
					<Script
						type="application/ld+json"
						id={uiStructuredDataSpeakable}
						{...addTestSelector(uiStructuredDataSpeakable)}
						dangerouslySetInnerHTML={{
							__html: JSON.stringify(speakable(article)),
						}}
					/>
				)}
		</>
	);
};

export { breadcrumbsData, faqData };
