export const providerTypes = [
	'article',
	'eventOverview',
	'search',
	'subsidies',
	'cookiePage',
	'coenOverview',
	'factsheet',
	'prinsjesdag',
	'transaction',
	'sdgArticle',
	'terugbetalen',
	'wettenEnRegelsSubject',
	'wettenEnRegelsOverview',
	'wetswijzigingen',
	'amendmentMainSubject',
	'glossary',
] as const;
export const legacyProviderTypes = [
	'rechtsvormen',
	'cbsFactsheet',
	'contactForm',
	'countryInfo',
	'contactOverview',
	'detacheren',
	'detacherenOverview',
	'eventDetail',
	'factsandfiguresOverview',
	'homepage',
	'incoterms',
	'landenOverzicht',
	'menu',
	'regelhulpenOverzicht',
	'regioDetail',
	'regioOverzicht',
	'sectorDetail',
	'sectorOverzicht',
	'showCaseOverview',
	'themaMenu',
	'wasteGuide',
	'webinardetail',
	// BGOV only:
	'homepageEn',
	'decisionTree',
	'browse',
	'comingToNL',
] as const;

export const allProviderTypes = [...providerTypes, ...legacyProviderTypes];

export type AllProviderTypes = typeof allProviderTypes[number];
