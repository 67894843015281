import { TranslationsMap } from '@dop/shared/translate/translations.types';

import { commonTranslations } from './common.translations';
import { questionTranslations } from './questions.translations';
import { bvTranslations } from './rechtsvormBv.translations';
import { cooperatieTranslations } from './rechtsvormCooperatie.translations';
import { eenmanszaakTranslations } from './rechtsvormEenmanszaak.translations';
import { maatschapTranslations } from './rechtsvormMaatschap.translations';
import { vofTranslations } from './rechtsvormVof.translations';

export const rechtsvormenTranslations = {
	...eenmanszaakTranslations,
	...bvTranslations,
	...vofTranslations,
	...maatschapTranslations,
	...cooperatieTranslations,
	...questionTranslations,
	...commonTranslations,
} as const;

// and cast `as TranslationsMap` to enforce 'en' and 'nl' languageCodes
rechtsvormenTranslations as TranslationsMap;
