import { TranslationsMap } from './translations.types';

export const dateTimeTranslations = {
	monday: {
		en: 'Monday',
		nl: 'Maandag',
	},
	tuesday: {
		en: 'Tuesday',
		nl: 'Dinsdag',
	},
	wednesday: {
		en: 'Wednesday',
		nl: 'Woensdag',
	},
	thursday: {
		en: 'Thursday',
		nl: 'Donderdag',
	},
	friday: {
		en: 'Friday',
		nl: 'Vrijdag',
	},
	saturday: {
		en: 'Saturday',
		nl: 'Zaterdag',
	},
	sunday: {
		en: 'Sunday',
		nl: 'Zondag',
	},
} as const;

// and cast `as TranslationsMap` to enforce 'en' and 'nl' languageCodes
dateTimeTranslations as TranslationsMap;
