import { HTMLContent } from '@dop/shared/components/markup/Markup.types';

import { allProviderTypes } from '@/globals/config/providerTypes';
import { EventOverviewWebApiData } from '@/app/eventOverview/EventOverview.types';
import { ArticleWebApiData } from '@/app/article/[[...slug]]/Article.types';
import { SubsidiesWebApiData } from '@/app/subsidies/Subsidies.types';
import { SdgArticleWebApiData } from '@/app/sdgArticle/SdgArticle.types';
import { CoenOverviewWebApiData } from '@/app/coenOverview/CoenOverview.types';
import { FactsheetWebApiData } from '@/app/factsheet/Factsheet.types';
import { WettenEnRegelsSubjectWebApiData } from '@/app/wettenEnRegelsSubject/WettenEnRegelsSubject.types';
import { WettenEnRegelsOverviewWebApiData } from '@/app/wettenEnRegelsOverview/[[...slug]]/WettenEnRegelsOverview.types';
import { WetswijzigingenWebApiData } from '@/app/wetswijzigingen/Wetswijzigingen.type';
import { AmendmentMainSubjectWebApiData } from '@/app/amendmentMainSubject/[[...slug]]/AmendmentMainSubject.types';
import { GlossaryWebApiData } from '@/app/glossary/Glossary.types';
import { PrinsjesdagWebApiData } from '@/app/prinsjesdag/[[...slug]]/Prinsjesdag.types';
import { TerugbetalenWebApiData } from '@/app/terugbetalen/[[...slug]]/Terugbetalen.types';
import { WasteGuidePageData } from '@dop/pages/wasteGuide/wasteGuide.types';

// This type is a union of all provider-type web-api data
export type WebApiPageData =
	| ArticleWebApiData
	| SdgArticleWebApiData
	| PrinsjesdagWebApiData
	| EventOverviewWebApiData
	| SubsidiesWebApiData
	| CoenOverviewWebApiData
	| FactsheetWebApiData
	| WetswijzigingenWebApiData
	| WettenEnRegelsSubjectWebApiData
	| WettenEnRegelsOverviewWebApiData
	| AmendmentMainSubjectWebApiData
	| GlossaryWebApiData
	| PrinsjesdagWebApiData
	| TerugbetalenWebApiData
	| WasteGuidePageData;

export type WebApi404Data = {
	status: '404';
	content?: HTMLContent;
	title: string;
	message: string;
};

export type WebApiData = WebApiPageData | WebApi404Data;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isWebApi404Data = (data: any): data is WebApi404Data => {
	return data?.status === '404';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isWebApiPageData = (data: any): data is WebApiPageData => {
	if (isWebApi404Data(data)) {
		return false;
	}
	return (
		data?.status === '200' && allProviderTypes.includes(data?.providerType)
	);
};

export function assertWebApiResponse<SpecificWebApiData>(
	data: unknown
): asserts data is SpecificWebApiData {
	if (!isWebApi404Data(data) && !isWebApiPageData(data)) {
		throw new Error('Invalid data from WebApi');
	}
}
